
<template>
  <div class="panel-margin">
    <TitleComponent
      @title-TITLE_UPDATED="updateItem"
      :placeholder="$t('fields.title')"
      :item="item">
    </TitleComponent>

    <ComplexEditor
      :questionnaire_id="parent_id"
      v-bind:editor_data.sync="editorData"
      v-on:blur="updateDescription"
      v-on:destroy="onDestroy"
      @data-changed="updateDescription"
      :placeholder="`${$t('description')}...`"
      :autoOpen="autoOpenEditor"
      :dontClose="dontCloseEditor"
      class="pt-4"
    ></ComplexEditor>

    <Footer :item="item"></Footer>
  </div>
</template>

<script>
import TitleComponent from './title/TitleComponent'
import ComplexEditor from '../ComplexEditor'
import Footer from '../Footer'

export default {
  components: {
    TitleComponent,
    ComplexEditor,
    Footer,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    parent_id: {
      type: Number,
      default: null,
    },
    autoOpenEditor: {
      type: Boolean,
      default: false
    },
    dontCloseEditor: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      editorData: this.item !== null && this.item.data && this.item.data.description ? this.item.data.description : '',
    }
  },
  methods: {
    updateDescription () {
      const data = this._.cloneDeep(this.item.data)
      data.description = this.editorData
      this.item.data = data
      this.update(this.item)
    },
    updateItem (item, type, value) {
      console.log('updateItem...', item, value)
      if (this.item.id === item.id) {
        const data = this._.cloneDeep(this.item.data)
        data.title = value
        item.data = data
        this.update(item)
      }
    },
    update(item) {
      this.$parent.$emit('item-ITEM_UPDATED', item)
    }
  },
  created() {
    this.$on('title-TITLE_UPDATED', (item, type, value) => {

    })
    this.$on('footer-DELETE_ITEM', (item) => {
      this.$parent.$emit('item-DELETE_ITEM', item)
    })

    this.$on('footer-DUPLICATE_ITEM', (item) => {
      this.$parent.$emit('item-DUPLICATE_ITEM', item)
    })

    this.$on('footer-ITEM_UPDATED', (item) => {
      this.$parent.$emit('item-ITEM_UPDATED', item)
    })
  },

  mounted() {
    this.$emit('questionRendered')
  }
}
</script>

<style>
</style>
